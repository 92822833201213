import cx from "clsx";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import React from "react";

import ButtonHamburgerV2 from "../../../NavbarV4/containers/NavbarOnly$Market/components/ButtonHamburgerV2";
import ButtonUserFusedWithButtonWallet from "../../components/ButtonUserFusedWithButtonWallet";
import LinkToHomePageWithKreateLogo from "../../components/LinkToHomePageWithKreateLogo";
import ProductSwitcher from "../../components/ProductSwitcher";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function Navbar$Min0px({ className, style }: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      <Flex.Row
        className={styles.content}
        padding="4px 24px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex.Row gap="12px" alignItems="center">
          <LinkToHomePageWithKreateLogo />
          <ProductSwitcher product="art" />
        </Flex.Row>
        <Flex.Row alignItems="center" gap="12px">
          <ButtonUserFusedWithButtonWallet />
          <ButtonHamburgerV2
            features={[
              "add-artwork",
              "add-collection",
              "separator",
              "explore-art",
              "separator",
              "switch-theme",
            ]}
          />
        </Flex.Row>
      </Flex.Row>
    </div>
  );
}
