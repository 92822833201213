import cx from "clsx";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import { ThemeContext } from "kreate-common/modules/kreate-ui/components/ThemeProvider/config";
import React from "react";

import ButtonAdd from "../../components/ButtonAdd";
import ButtonExplore from "../../components/ButtonExplore";
import ButtonSwitchTheme from "../../components/ButtonSwitchTheme";
import ButtonUserFusedWithButtonWallet from "../../components/ButtonUserFusedWithButtonWallet";
import LinkToHomePageWithKreateLogo from "../../components/LinkToHomePageWithKreateLogo";
import ProductSwitcher from "../../components/ProductSwitcher";
import SearchBar from "../SearchBar";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  hideSearchBarOnNavbar?: boolean;
};

export default function Navbar$Min1200px({
  className,
  style,
  hideSearchBarOnNavbar,
}: Props) {
  const theme = React.useContext(ThemeContext);
  return (
    <div className={cx(styles.container, className)} style={style}>
      <Flex.Row
        className={styles.content}
        padding="4px 24px"
        alignItems="center"
        justifyContent="space-between"
        gap="12px"
      >
        <Flex.Row
          alignItems="center"
          gap="16px"
          flex="1 1 64px"
          minWidth="64px"
        >
          <LinkToHomePageWithKreateLogo style={{ flex: "0 0 auto" }} />
          <ProductSwitcher product="art" />
          {!hideSearchBarOnNavbar ? (
            <SearchBar
              style={{ flex: "1 1 64px", minWidth: "64px", marginLeft: "8px" }}
            />
          ) : null}
        </Flex.Row>
        <Flex.Row alignItems="center" gap="12px">
          <ButtonExplore />
          <ButtonUserFusedWithButtonWallet />
          <ButtonAdd />
          {theme !== "default" ? <ButtonSwitchTheme /> : null}
        </Flex.Row>
      </Flex.Row>
    </div>
  );
}
