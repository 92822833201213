import * as RxSelect from "@radix-ui/react-select";
import CustomButton from "kreate-common/modules/kreate-ui/components/CustomButton";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import * as React from "react";
import { MdArrowDropDown } from "react-icons/md";

import styles from "./index.module.scss";

import { EM_SPACE } from "@/modules/common-utils/unicode";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  options: Record<string, React.ReactNode>;
  value: string;
  onChange?: (value: string) => void;
};

const ButtonScope = CustomButton.withDefaultProps({
  className: styles.buttonScope,
  color: "inherit",
  size: "xs",
  padding: "inherit",
});

export default function SelectScope({
  className,
  style,
  options: items,
  value,
  onChange,
}: Props) {
  return (
    <div className={className} style={style}>
      <RxSelect.Root value={value} onValueChange={onChange}>
        <RxSelect.Trigger asChild>
          <ButtonScope
            iconRight={
              <RxSelect.Icon asChild>
                <MdArrowDropDown
                  className={styles.arrowDropDown}
                  size="24px"
                  style={{ margin: "-7px" }}
                />
              </RxSelect.Icon>
            }
          >
            <RxSelect.Value asChild>
              <div className={styles.value}>
                <span>{"in" + EM_SPACE}</span>
                <strong>{items[value]}</strong>
              </div>
            </RxSelect.Value>
          </ButtonScope>
        </RxSelect.Trigger>
        <RxSelect.Portal>
          <RxSelect.Content className={styles.content} position="popper">
            <RxSelect.Viewport className={styles.viewport}>
              <Flex.Col>
                {Object.entries(items).map(([name, label]) => (
                  <RxSelect.Item asChild key={name} value={name}>
                    <button className={styles.item}>
                      <RxSelect.ItemText>{label}</RxSelect.ItemText>
                    </button>
                  </RxSelect.Item>
                ))}
              </Flex.Col>
            </RxSelect.Viewport>
            <RxSelect.Arrow className={styles.arrow} />
          </RxSelect.Content>
        </RxSelect.Portal>
      </RxSelect.Root>
    </div>
  );
}
