import cx from "clsx";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import { useRouter } from "next/router";
import React from "react";
import { MdSearch } from "react-icons/md";

import InputScope from "./components/InputScope";
import SelectScope from "./components/SelectScope";
import styles from "./index.module.scss";

import {
  getLinkToPageGalleryArtwork,
  getLinkToPagePolicy,
} from "@/modules/navigation/utils/getLinkTo";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function SearchBar({ className, style }: Props) {
  const router = useRouter();
  const [searchInput, setSearchInput] = React.useState("");
  const [scope, setScope] = React.useState<
    "artists" | "artworks" | "collections"
  >("artworks");
  const [busy, setBusy] = React.useState(false);

  const handleSubmit = async () => {
    {
      // TODO: @kien-kreate: deduplicate with
      // apps/kreate-web/modules/kreate-components/components/NavbarV4/containers/NavbarOnly$Market/components/SearchBar/index.tsx
      const searchQuery = searchInput.trim();
      if (searchQuery.startsWith("asset1")) {
        await router.push(getLinkToPageGalleryArtwork(searchQuery, "info"));
        return;
      } else if (/^[0-9a-f]{56}$/.test(searchQuery)) {
        await router.push(getLinkToPagePolicy(searchQuery));
        return;
      } else if (/^[0-9a-f]{57,}$/.test(searchQuery)) {
        await router.push(getLinkToPageGalleryArtwork(searchQuery, "info"));
        return;
      }
    }
    try {
      setBusy(true);
      switch (scope) {
        case "artworks":
          return await router.push({
            pathname: "/explore/artworks",
            query: { q: searchInput, mintStatus: "all" },
          });
        case "artists":
          return await router.push({
            pathname: "/explore/artists",
            query: { q: searchInput },
          });
        case "collections":
          return await router.push({
            pathname: "/explore/collections",
            query: { q: searchInput },
          });
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <div className={cx(styles.container, className)} style={style}>
      <Flex.Row gap="12px">
        <Flex.Row flex="1 1 64px" minWidth="64px" alignItems="center" gap="8px">
          {<MdSearch className={styles.iconSearch} size="24px" />}
          <InputScope
            style={{ flex: "1 1 64px", minWidth: "64px" }}
            value={searchInput}
            onChange={setSearchInput}
            onSubmit={handleSubmit}
            disabled={busy}
          />
        </Flex.Row>
        <Flex.Row flex="0 0 auto" alignItems="center">
          <SelectScope
            value={scope}
            onChange={(scope) => {
              scope === "artworks" && setScope("artworks");
              scope === "collections" && setScope("collections");
              scope === "artists" && setScope("artists");
            }}
            options={{
              artworks: "Artworks",
              collections: "Collections",
              artists: "Artists",
            }}
          />
        </Flex.Row>
      </Flex.Row>
    </div>
  );
}
