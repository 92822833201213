import cx from "clsx";
import CustomButton from "kreate-common/modules/kreate-ui/components/CustomButton";
import React from "react";
import { MdCancel } from "react-icons/md";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  onSubmit?: () => void;
};

const ButtonClear = CustomButton.withDefaultProps({
  className: styles.buttonClear,
  padding: "minimal",
  color: "inherit",
  size: "xs-square",
});

export default function InputScope({
  className,
  style,
  value,
  onChange,
  disabled,
  onSubmit,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      <input
        className={styles.input}
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
        placeholder="Search"
        disabled={disabled}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSubmit?.();
          }
        }}
      />
      <ButtonClear
        style={{ opacity: value ? "100%" : "0%" }}
        title="Clear"
        onClick={() => onChange?.("")}
      >
        <MdCancel size="24px" />
      </ButtonClear>
    </div>
  );
}
