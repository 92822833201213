import cx from "clsx";
import DropdownMenu from "kreate-common/modules/kreate-ui/components/DropdownMenu";
import { useRouter } from "next/router";
import React from "react";
import {
  MdOutlineArrowDropDown,
  MdPeopleAlt,
  MdPermMedia,
  MdPhotoSizeSelectActual,
  MdSearch,
} from "react-icons/md";

import ButtonOnNavbar from "../ButtonOnNavbar";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  showIcon?: boolean;
};

export default function ButtonExplore({ className, style, showIcon }: Props) {
  const router = useRouter();
  const [loading, setLoading] = React.useState(false);

  return (
    <div className={cx(styles.container, className)} style={style}>
      <DropdownMenu.Menu
        trigger={
          <ButtonOnNavbar
            iconRight={
              <MdOutlineArrowDropDown
                size="24px"
                style={{ margin: "0 -7px" }}
              />
            }
            content={showIcon ? <MdSearch size="24px" /> : "Explore"}
            loading={loading}
            disabled={loading}
          />
        }
      >
        <DropdownMenu.Item
          icon={<MdPhotoSizeSelectActual />}
          onSelect={async () => {
            try {
              setLoading(true);
              await router.push("/explore/artworks");
            } finally {
              setLoading(false);
            }
          }}
        >
          {"Artworks"}
        </DropdownMenu.Item>
        <DropdownMenu.Item
          icon={<MdPermMedia />}
          onSelect={async () => {
            try {
              setLoading(true);
              await router.push("/explore/collections");
            } finally {
              setLoading(false);
            }
          }}
        >
          {"Collections"}
        </DropdownMenu.Item>
        <DropdownMenu.Item
          icon={<MdPeopleAlt />}
          onSelect={async () => {
            try {
              setLoading(true);
              await router.push("/explore/artists");
            } finally {
              setLoading(false);
            }
          }}
        >
          {"Artists"}
        </DropdownMenu.Item>
      </DropdownMenu.Menu>
    </div>
  );
}
