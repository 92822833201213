import cx from "clsx";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import { ThemeContext } from "kreate-common/modules/kreate-ui/components/ThemeProvider/config";
import React from "react";

import ButtonAdd from "../../components/ButtonAdd";
import ButtonExplore from "../../components/ButtonExplore";
import ButtonSwitchTheme from "../../components/ButtonSwitchTheme";
import ButtonUserFusedWithButtonWallet from "../../components/ButtonUserFusedWithButtonWallet";
import LinkToHomePageWithKreateLogo from "../../components/LinkToHomePageWithKreateLogo";
import ProductSwitcher from "../../components/ProductSwitcher";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function Navbar$Min768px({ className, style }: Props) {
  const theme = React.useContext(ThemeContext);

  return (
    <div className={cx(styles.container, className)} style={style}>
      <Flex.Row
        className={styles.content}
        padding="4px 24px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex.Row gap="12px" alignItems="center">
          <LinkToHomePageWithKreateLogo />
          <ProductSwitcher product="art" />
        </Flex.Row>
        <Flex.Row alignItems="center" gap="12px">
          <ButtonExplore showIcon />
          <ButtonUserFusedWithButtonWallet />
          <ButtonAdd />
          {theme !== "default" ? <ButtonSwitchTheme /> : null}
        </Flex.Row>
      </Flex.Row>
    </div>
  );
}
